<template>
    <div ref="SensorTrackingTable">
        <b-col cols="12" class="basic-card p-2" v-if="statusStep === 1">
            <b-table-simple
                    responsive
                    hover
                    sticky-header
                    borderless
                    class="sensor-array table-height"
                    v-if="trackers.length > 0 && !isBusy"
            >
                <b-thead class="table-column-header-title">
                    <b-tr>
                        <b-th class="add-cursor">Capteurs associé(s)</b-th>
                        <b-th>Statut</b-th>
                        <b-th>Dernière MAJ</b-th>
                        <b-th>Depuis le</b-th>
                        <b-th>Bon de Transport</b-th>
                        <b-th>Id de Suivi</b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr
                            v-for="(tracking,i) in inTransitTrackings"
                            :key="`sensor_tracking_${i}`"
                            class="clr-dark-grey add-cursor"
                    >
                        <b-td>
                            <div class="d-flex px-1 py-1 justify-content-start"
                                 @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')"
                                 v-for="(sensor,i) in tracking.sensors"
                                 :key="'tracking_sensor_'+i">
                                <h6 class="mb-0 add-cursor clr-orange">
                                    {{ sensor.serialNumber }}
                                </h6>
                            </div>
                        </b-td>
                        <b-td>
                            <p>{{ tracking.trackingStatus }}</p>
                        </b-td>
                        <b-td>
                            <p>{{ tracking.updatedAt | dateDayMonth }}</p>
                        </b-td>
                        <b-td>
                            <p>{{ tracking.createdAt | dayDate }}</p>
                        </b-td>
                        <b-td>
                            <p>{{ tracking.trackingNumber }}</p>
                        </b-td>
                        <b-td>
                            <p>{{ tracking.trackingId }}</p>
                        </b-td>
                        <b-td class="text-center">
                            <b-button id="show-btn"
                                      class="bg-white clr-orange border-orange"
                                      @click="sendToExternalLink('https://www.tnt.fr/public/suivi_colis/recherche/visubontransport.do?bonTransport='+tracking.trackingNumber)">
                                Suivi TNT
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
        <b-col cols="12" class="basic-card p-2" v-else>
            <b-table-simple
                    responsive
                    hover
                    sticky-header
                    borderless
                    class="table-height"
                    v-if="sensors.length > 0 && !isBusy"
            >
                <b-thead class="table-column-header-title">
                    <b-tr>
                        <b-th class="add-cursor">Capteur</b-th>
                        <b-th>Client</b-th>
                        <b-th>Depuis le</b-th>
                        <b-th>Action</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr class="clr-darker-grey add-cursor"
                        v-for="(sensor, index) in sensors"
                        :key="`waitingForInstallation_sensors_${index}`">
                        <b-td @click="$router.push('/sensors/'+sensor.serialNumber+'/detail')">
                            <div class="d-flex px-1 py-1 justify-content-start">
                                <h6 class="mb-0 clr-orange">
                                    {{ sensor.serialNumber }}
                                </h6>
                            </div>
                        </b-td>
                        <b-td>
                            <router-link :to="'/accounts/'+sensor.owningAccount.id"
                                         class="clr-darker-grey">
                                {{ sensor.owningAccount.name }}
                            </router-link>
                        </b-td>
                        <b-td>
                            <span class="text-secondary font-weight-bold clr-red">{{ sensor.lastUpdate }}</span>
                        </b-td>
                        <b-td>
                            <p class="font-weight-bold clr-orange"
                               @click="$bvModal.show('contact-modal-'+sensor.serialNumber)">Contact</p>
                        </b-td>
                        <b-modal :ref="'contact-modal-'+sensor.serialNumber"
                                 :id="'contact-modal-'+sensor.serialNumber"
                                 hide-footer
                                 title="Contacts Renseignés"
                                 size="lg"
                                 class="p-2"
                        >
                            <b-container fluid>
                                <b-row class="rounded bg-lighter-grey p-3"
                                       v-for="(contact,i) in sensor.dataReference.accountContacts"
                                       :key="i">
                                    <b-col cols="6" class="clr-dark-grey">Contact n°{{ i }}</b-col>
                                    <b-col cols="6" class="float-right"><span
                                        class="bold clr-dark-grey ">dernière maj. </span>:
                                        {{ contact.updatedAt | dateDayMonth }}
                                    </b-col>
                                    <b-col cols="6"><span class="bold">Nom </span>:
                                        {{ contact.firstName | capitalize }}
                                        {{ contact.lastName | capitalize }}
                                    </b-col>
                                    <b-col cols="6"><span class="bold">Email </span>: {{
                                            contact.email
                                        }}
                                    </b-col>
                                    <b-col cols="6"><span
                                        class="bold">Prénom </span>:{{ contact.phoneNumber }}
                                    </b-col>
                                </b-row>
                            </b-container>

                            <b-button class="mt-3" variant="outline-danger" block
                                      @click="$bvModal.hide('contact-modal-'+sensor.serialNumber)">
                                Fermer
                            </b-button>
                        </b-modal>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "SensorDeployTable",
    props: {
        sensors: Array,
        statusStep: {
            type: Number,
            default: 1
        },
        hoveredSensor: String,
        sort: String,
        direction: String,
        isBusy: Boolean
    },
    data() {
        return {}
    },
    methods: {
        ...Vuex.mapActions('deliveryTracking', {
            setTrackings: 'setTrackings',
        }),
        handler(event, data) {
            this.viewMenu = true;
            this.rightClickedSensor = data
            setTimeout(() => {
                this.$refs.right.focus();
                this.setMenu(event.y, event.x)
            }, 0);
            event.preventDefault();
        },
        setSort(value) {
            this.$emit('set-sorting', value)
        },
        setHoveredSensor(sensorId) {
            this.$emit('hovered-sensor', sensorId)
        },
        sendToExternalLink(url) {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },
    computed: {
        ...Vuex.mapGetters('deliveryTracking', {
            sensorTrackings: 'sensorTrackings',
        }),
        inTransitTrackings() {
            return this.sensorTrackings.filter(tracking => !tracking.trackingStatus.includes('delivered'))
        },
        trackers(){
            if (this.statusStep){
                let sensorsTracked = this.sensors.map(item => item.serialNumber)
                return this.sensorTrackings.filter(tracker => tracker.sensors.some(s => sensorsTracked.includes(s.serialNumber)))
            } else {
                return this.sensorTrackings
            }
        }
    },
    async created() {
        await this.setTrackings()
    }
}
</script>

<style scoped lang="scss">
</style>
