import Vue from "vue";

export const navigation = Vue.observable({
	isNavOpen: false
});

// We call toggleNav anywhere we need it in our app
export const mutations = {
	toggleNav() {
		navigation.isNavOpen = !navigation.isNavOpen
	}
};
